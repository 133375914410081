// Entry point for the build script in your package.json

import jquery from "jquery"
window.jQuery = jquery;
window.$ = jquery;

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

require('@doabit/semantic-ui-sass');
require("semantic-ui-calendar/dist/calendar.js");

Rails.start()
Turbolinks.start()

document.addEventListener('turbolinks:load', function(event) {
  if (typeof(ga) === 'function') {
    ga('set', 'location', event.data.url);
    ga('send', 'pageview');
  }

  $('#check_date').wrap('<div class="ui calendar"><div class="ui left icon input"></div></div>').parent().append('<i class="icon calendar"></i>');
  $('.ui.calendar').calendar({type: 'date'});
  $('#check_amount').wrap('<div class="ui left icon input"></div>').parent().append('<i class="icon dollar"></i>');
});

$(document).on('click', 'a.toggle', function(e) {
  e.preventDefault();
  e.stopPropagation();
  $($(this).attr('href')).toggle();
});
